import { LOGIN_UI, REGION_GATEWAY } from './constants';
import { sendRequest } from './apiCall';

export async function screenLoader(data) {
  const { sId, screen, service } = data;
  let entityMasterData;
  let layoutData;
  let entityData;
  let extensionEntityData;
  try {
    const { response, responseError } = await sendRequest({
      url: `${REGION_GATEWAY}/${service}/instances/layout?sId=${sId}&screen=${
        screen ? screen : 'list'
      }`,
      method: 'GET',
    });
    if (response) {
      layoutData = response.data;
    }
    if (responseError && responseError.response.status === 401) {
      window.location.replace(`${LOGIN_UI}`);
    }
    // TODO: Need to clean up. Bring in an option in the framework
    // to do the replacement
    const url = new URL(window.location.href);
    const checklist = url.searchParams.get('checklist');

    const entityResponse = await sendRequest({
      url: checklist
        ? `${REGION_GATEWAY}/${service}/entity?sId=checklist_instances-${checklist}`
        : `${REGION_GATEWAY}/${service}/entity?sId=${sId}`,
      method: 'GET',
    });
    entityMasterData = entityResponse.response.data;
  } catch (error) {
    console.log(error);
  }
  layoutData = layoutData?.data?.instances[0];
  entityData = entityMasterData?.data?.Entity[0];
  extensionEntityData = entityMasterData?.data?.extensionEntity?.length
    ? entityMasterData?.data?.extensionEntity[0]
    : {};
  return {
    layout: layoutData,
    entity: entityData,
    extensionEntity: extensionEntityData,
    layoutId: layoutData?._id,
  };
}
