import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { sendRequest } from '../../utils/apiCall';
import { REGION_GATEWAY } from '../../utils/constants';

// Define the shape of the state for a particular endpoint
interface ApiState {
  data: {
    [key: string]: {
      [query: string]: any[]; 
    };
  };
  loading: {
    [key: string]: {
      [query: string]: boolean; 
    };
  };
  error: {
    [key: string]: {
      [query: string]: string | null;
    };
  };
}

// Initial state with default values
const initialState: ApiState = {
  data: {},
  loading: {},
  error: {},
};

// Define a type for the fetchData action arguments
interface FetchDataArgs {
  endpoint: string;
  query?: string;
  service: string;
}

// Fetch Data (GET) with TypeScript support
export const fetchData = createAsyncThunk(
  'api/fetchData',
  async ({ endpoint, query, service }: FetchDataArgs) => {
    let url = `${REGION_GATEWAY}/${service}/instances/${endpoint}`;
    if (query) {
      url += `?${query}`;
    }
    console.log(`Fetching URL: ${url}`);
    const { response } = await sendRequest({ url, method: 'GET' });
    return { data: response.data.data.instances, endpoint, query };
  },
);

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle Fetch Data actions
      .addCase(fetchData.pending, (state, action) => {
        const { endpoint, query } = action.meta.arg;
        if (!state.data[endpoint]) {
          state.data[endpoint] = {};
        }
        state.loading[endpoint] = {
          ...state.loading[endpoint],
          [query || 'default']: true, // Track loading state for each query
        };
      })
      .addCase(
        fetchData.fulfilled,
        (state, action: PayloadAction<{ data: any[]; endpoint: string; query?: string }>) => {
          const { data, endpoint, query } = action.payload;
          state.data[endpoint][query || 'default'] = data; // Store data per query or in default key
          state.loading[endpoint] = {
            ...state.loading[endpoint],
            [query || 'default']: false,
          };
          state.error[endpoint] = {
            ...state.error[endpoint],
            [query || 'default']: null,
          };
        },
      )
      .addCase(fetchData.rejected, (state, action) => {
        const { endpoint, query } = action.meta.arg;
        state.loading[endpoint] = {
          ...state.loading[endpoint],
          [query || 'default']: false,
        };
        state.error[endpoint] = {
          ...state.error[endpoint],
          [query || 'default']: action.error.message || null,
        };
      });
  },
});

export default apiSlice.reducer;
