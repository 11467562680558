import { Fragment, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { eraseCookie } from '../../utils/storageHelpers';
import { LOGIN_UI } from '../../utils/constants';
import { Dialog, Transition } from '@headlessui/react';
import { useMatches } from 'react-router-dom';

//Tryout
// const timeout = 1000 * 60 * 1
// const promptBeforeIdle = 1000 * 50

const timeout = 1000 * 60 * 60;
const promptBeforeIdle = 1000 * 15;
const andonTime = 1000 * 60 * 60 * 24;

function SessionTimeout() {
  const { params } = useMatches()[0];
  const screen = params.screen; // Adjust this line based on where screen is coming from
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  const onIdle = () => {
    if (params.sId === 'andon' && screen === 'andon_view') return;
    setOpen(false);
    setRemaining(0);
    handleLogOut();
  };

  const onActive = () => {
    if (params.sId === 'andon' && screen === 'andon_view') return;
    setOpen(false);
    setRemaining(0);
  };

  const onPrompt = () => {
    if (params.sId === 'andon' && screen === 'andon_view') return;
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    crossTab: true,
    syncTimers: 200,
  });

  useEffect(() => {
    if (params.sId === 'andon' && (screen === 'andon_view' || screen === 'quality_andon')) {
    // 24-hrs auto logout for andon_view & quality_andon
      const andonTimeout = setTimeout(() => {
        handleLogOut(); // Log out after 24 hrs
      }, andonTime);

      return () => {
        clearTimeout(andonTimeout);
      };
    } else {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }, 500);

      return () => {
        clearInterval(interval);
      };
    }
  }, [params.sId, screen, getRemainingTime]);

  const handleStillHere = () => {
    if (params.sId === 'andon' && screen === 'andon_view') return;
    activate();
  };

  const handleLogOut = () => {
    eraseCookie('access_token');
    localStorage.clear();
    window.location.href = LOGIN_UI;
    console.log('logged out');
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-40"
            leave="ease-in duration-200"
            leaveFrom="opacity-40"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center text-center md:items-start sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 lg:w-full lg:max-w-lg sm:p-6">
                  <div>
                    <div className="text-center">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Your session is about to expire. Logging out in,{' '}
                        <span style={{ color: 'red' }}>
                          00:{remaining < 10 ? '0' : ''}
                          {remaining}
                        </span>
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 flex flex-wrap justify-center gap-10">
                    <div>
                      <button
                        type="button"
                        className="rounded-md border border-transparent bg-indigo-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:text-sm outline-none"
                        onClick={() => {
                          handleStillHere();
                          setOpen(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 sm:text-sm outline-none"
                        onClick={() => {
                          handleLogOut();
                          setOpen(false);
                        }}
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default SessionTimeout;
