import { getProfileFromLocalStorage } from '../utils/helperFunctions';

export function useProfileIconName() {
  let profileIconName = '';
  const user = getProfileFromLocalStorage();
  if (user) {
    let _profileName = user?.firstName;
    if (_profileName) {
      profileIconName = _profileName
        .split(' ')
        .map((name) => name[0])
        .join('')
        .toUpperCase();
    }
  }
  return profileIconName;
}
