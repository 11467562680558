import { useEffect, useState } from 'react';
import regereLogo from '../images/logo192.png';
import {
  TENANT_SUBDOMAIN_OPTIONS,
  REGION_GATEWAY,
  LOGO_URL,
  BANNER_BG_STYLE,
  BANNER_IMAGE_URL,
} from '../utils/constants';
import OtpInput from 'react-otp-input';
import { useAppContext } from '../provider/AppProvider';
import { sendRequest } from '../utils/apiCall';
import { setCookie } from '../utils/storageHelpers';
import { useProfileData } from '../hooks/useProfileData';

export default function Login() {
  const { theme, userProfile, setUserProfile } = useAppContext();
  const { getUserProfile } = useProfileData();

  const [loading, setLoading] = useState(false);
  const [subdomain, setSubdomain] = useState(
    TENANT_SUBDOMAIN_OPTIONS.length ? JSON.parse(TENANT_SUBDOMAIN_OPTIONS)[0] : '',
  );
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const [loginType, setLoginType] = useState('username');
  const [otpScreen, setOtpScreen] = useState(false);
  const [otp, setOtp] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [resendOTP, setResendOTP] = useState(false);

  useEffect(() => {
    const handleBackButton = (event) => {
      // Perform your custom operation here
      event.preventDefault();
      // You can add your custom logic or prevent the default behavior
      // For example:
      // event.preventDefault();
      // Perform your action like redirecting to another page
      // window.location.href = '/custom-route';
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    let requestPayload;
    if (loginType === 'username') {
      requestPayload = {
        loginType: 'username',
        username: username,
        password: password,
        subdomain: subdomain,
      };
    } else if (loginType === 'email') {
      requestPayload = {
        loginType: 'email',
        email: username,
        password: password,
        subdomain: subdomain,
      };
    }

    // TODO: Do an API call
    if (!username && !password && !subdomain) {
      setErrorMessage('Username, Password & subdomain cannot be empty');
    } else if (!username) {
      setErrorMessage('Username cannot be empty.');
    } else if (!password) {
      setErrorMessage('Password cannot be empty.');
    } else if (!subdomain) {
      setErrorMessage('Sudomain cannot be empty.');
    } else {
      const response = await fetch(`${REGION_GATEWAY}/coreV2/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify(requestPayload),
      });
      const loginResponse = await response.json();

      console.log(loginResponse);
      if (loginResponse.status) {
        if (loginResponse?.data?.token) {
          if (resendOTP) {
            setOtpErrorMessage('');
          }
          setAccessToken(loginResponse?.data?.token);
          setOtpScreen(true);
        } else {
          setCookie('access_token', loginResponse?.data?.redirectUrl, 7);
          setUserProfile(await getUserProfile());
        }
      } else {
        setErrorMessage('Please enter valid credentials');
        setLoading(false);
      }
    }
  };

  const handleVerifyAccount = async (e) => {
    e.preventDefault();
    if (otp.length < 6) {
      setOtpErrorMessage('Please enter valid OTP');
    }
    if (otp && otp.length === 6 && accessToken) {
      let requestPayload = {
        enteredOTP: otp,
        username: username,
      };
      const response = await fetch(`${REGION_GATEWAY}/coreV2/validateOTP`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(requestPayload),
      });
      const validateOTPResponse = await response.json();

      console.log(validateOTPResponse);
      if (validateOTPResponse.status) {
        setCookie('access_token', accessToken, 7);
        setUserProfile(await getUserProfile());
      } else {
        setOtpErrorMessage(
          'Sorry, the OTP has expired or is incorrect. Please request a new one by clicking the link below',
        );
        setOtp('');
        setResendOTP(true);
      }
    }
  };

  function handleUsernameChange(event) {
    const value = event.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmail = emailRegex.test(value);
    setLoginType(isEmail ? 'email' : 'username');
    setUsername(value);
  }

  function handleSubdomainChange(event) {
    const value = event.target.value;
    setSubdomain(value);
  }

  function handleInputChange(typedOtp) {
    setOtp(typedOtp);
  }

  if (userProfile) {
    // navigate("/home")
    console.log(userProfile);
    window.location.reload();
  }

  return (
    <>
      <div className="grid h-full grid-cols-1 sm:grid-cols-2 bg-gray-100 items-center overflow-hidden">
        <div
          className={`relative hidden sm:block bg-gradient-to-b bg-no-repeat bg-padding-box bg-clip-border w-full flex items-center justify-center h-screen ${
            BANNER_BG_STYLE === '2' ? 'from-lime-200 to-green-600' : 'from-indigo-50 to-indigo-500'
          }`}
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <img src={BANNER_IMAGE_URL} alt="side_image" className="m-auto" />
          </div>
        </div>

        {!otpScreen ? (
          <div className="flex flex-col items-center justify-center h-screen ">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              {LOGO_URL.length ? (
                <img className="mx-auto h-12 w-auto" src={LOGO_URL} alt="Workflow" />
              ) : (
                <img className="mx-auto h-12 w-auto" src={regereLogo} alt="Workflow" />
              )}
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                Sign in to your account
              </h2>
            </div>

            <div className="mt-8 sm:mx-auto lg:w-full md:max-w-md ">
              <div className="bg-white py-10 px-4 shadow sm:rounded-lg sm:px-10">
                <form className="space-y-6" onSubmit={login}>
                  {TENANT_SUBDOMAIN_OPTIONS && TENANT_SUBDOMAIN_OPTIONS.length && (
                    <div>
                      {JSON.parse(TENANT_SUBDOMAIN_OPTIONS).length === 0 && (
                        <label
                          htmlFor="username"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Subdomain
                        </label>
                      )}
                      <div className="mt-1">
                        {JSON.parse(TENANT_SUBDOMAIN_OPTIONS).length > 1 && (
                          <select
                            className="w-full rounded-md border-slate-300"
                            id="subdomain"
                            name="subdomain"
                            onChange={handleSubdomainChange}
                          >
                            {JSON.parse(TENANT_SUBDOMAIN_OPTIONS).map((_option, index) => {
                              return (
                                <option key={index} value={_option}>
                                  {_option.toUpperCase()}
                                </option>
                              );
                            })}
                          </select>
                        )}

                        {JSON.parse(TENANT_SUBDOMAIN_OPTIONS).length === 0 && (
                          <input
                            id="subdomain"
                            name="subdomain"
                            autoComplete={'subdomain'}
                            type={'text'}
                            placeholder="Subdomain"
                            value={subdomain}
                            onChange={handleSubdomainChange}
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          />
                        )}
                      </div>
                    </div>
                  )}
                  <div>
                    <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                      Username
                    </label>
                    <div className="mt-1">
                      <input
                        id="username"
                        name="username"
                        type={loginType}
                        autoComplete={loginType}
                        placeholder="Email or EmployeeId"
                        value={username}
                        onChange={handleUsernameChange}
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div style={{ marginTop: '1.0rem' }}>
                      <div className="text-red-500 text-sm">{errorMessage}</div>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      disabled={loading}
                    >
                      {loading && (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      {loading ? 'Authenticating...' : 'Login'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-12">
              <div className="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
                <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
                  <div className="flex flex-col items-center justify-center text-center space-y-2">
                    <div className="font-semibold text-3xl">
                      <p>One-Time Password Verification</p>
                    </div>
                    <div className="flex flex-row text-sm font-medium text-gray-400">
                      <p>Enter the OTP sent to your mobile number</p>
                    </div>
                  </div>

                  <div>
                    <form>
                      <div className="flex flex-col space-y-16">
                        <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs">
                          <OtpInput
                            value={otp}
                            onChange={handleInputChange}
                            numInputs={6}
                            separator={<span className="mx-auto px-2">-</span>}
                            isInputNum={true}
                            containerStyle={'w-16 h-16'}
                            inputStyle={{
                              width: '2em',
                              textAlign: 'center',
                              outline: 'none',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                              fontSize: '1.2em',
                            }}
                          />
                        </div>

                        <div className="flex flex-col space-y-5">
                          <div>
                            <button
                              type="submit"
                              onClick={handleVerifyAccount}
                              className="flex w-full py-5 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              Verify Account
                            </button>
                          </div>

                          <div style={{ marginTop: '1.0rem' }}>
                            <div className="text-red-500 text-sm text-center px-2">
                              {otpErrorMessage}
                            </div>
                          </div>

                          <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                            <p>Didn't recieve code?</p>{' '}
                            <a
                              className="flex flex-row items-center text-indigo-600"
                              href="http://"
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={login}
                            >
                              Resend
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
