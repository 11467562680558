import React, { useEffect } from 'react';
import { useState, useCallback, Fragment } from 'react';
import { H3, Input, P, PrimayButtonSmall } from './twEssential';
import { Dialog, Transition } from '@headlessui/react';
import GridLayout from './GridLayout';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { setDisableButtons, setFragmentData } from '../features/screen/screenSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getFragmentKey } from '../utils/helperFunctions';

function Popup(props) {
  const { showPopup, handleClose, layout, entity, extensionEntity, params, filterParams } = props;
  const fragmentKey = getFragmentKey(params);
  let fragmentData = useSelector((state) => state.screen.data[fragmentKey]);
  let restrictNavigation = useSelector((state) => state.screen.restrictNavigation);
  const dispatch = useDispatch();

  const closeClick = () => {
    if (restrictNavigation) {
      let result = window.confirm('You have unsaved changes. Are you sure you want to leave ?');
      if (result) {
        handleBrowserClose();
      }
    } else {
      handleBrowserClose();
    }
  };

  const handleBrowserClose = () => {
    dispatch({
      ...setFragmentData({ ...fragmentData, contextVars: '' }),
      fragmentKey: fragmentKey,
    });
    dispatch({ ...setFragmentData({}), fragmentKey: fragmentKey });
    handleClose();
  };

  return (
    <Transition appear show={showPopup} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => closeClick()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-xl px-4 pt-4 overflow-visible text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <button
                  className="absolute top-0 p-2 text-red-600 -right-0 hover:text-red-800"
                  onClick={() => closeClick()}
                >
                  <XCircleIcon className="w-6 h-6" />
                </button>

                <GridLayout
                  data={layout?.components[0]}
                  actions={layout?.actions}
                  entity={entity}
                  extensionEntity={extensionEntity}
                  fields={layout?.fields}
                  conainter="popup"
                  params={params}
                  closePopup={handleBrowserClose}
                  filterParams={filterParams}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Popup;
