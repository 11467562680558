import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    message: '',
    type: '',
    show: false,
  },
  reducers: {
    setNotification: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.status = action.payload.status;
    },
  },
});

export const triggerNotification = (message, success) => {
  return (dispatch) => {
    const payload = {
      message: message,
      type: success === 'success' ? 'success' : 'error',
      status: true,
    };
    dispatch(notificationSlice.actions.setNotification(payload));
    setTimeout(() => {
      const payload = {
        message: '',
        type: success ? 'success' : 'error',
        status: false,
      };
      dispatch(notificationSlice.actions.setNotification(payload));
    }, 2000);
  };
};

export const { showNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
