// EventBus.js
class EventBus {
  constructor() {
    this.eventListeners = {};
  }

  addEventListener(eventName, callback) {
    if (!this.eventListeners[eventName]) {
      this.eventListeners[eventName] = [];
    }
    this.eventListeners[eventName].push(callback);
  }

  removeEventListener(eventName, callback) {
    const listeners = this.eventListeners[eventName];
    if (listeners) {
      this.eventListeners[eventName] = listeners.filter((listener) => listener !== callback);
    }
  }

  emit(eventName, data) {
    const listeners = this.eventListeners[eventName];
    if (listeners) {
      listeners.forEach((listener) => listener(data));
    }
  }
}

// Create an instance of EventBus
const eventBus = new EventBus();

export default eventBus;
