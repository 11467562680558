import { Transition, Dialog } from '@headlessui/react';
import _ from 'lodash';
import { Fragment } from 'react';
import Lottie from 'lottie-react';
import loadingImage from '../animations/loadingPageImg.json';

export function ConfirmationPopup(props) {
  const {
    open,
    setOpen,
    title,
    description,
    id,
    onPrimaryButtonClick,
    buttonName,
    type = 'default',
  } = props;
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-40"
          leave="ease-in duration-200"
          leaveFrom="opacity-40"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 lg:w-full lg:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                  </div>
                </div>
                {!buttonName && <Lottie animationData={loadingImage} loop={true} />}
                <p className="text-center pt-1">{description}</p>

                <div className="mt-5 sm:mt-6 flex flex-wrap justify-center gap-10">
                  {buttonName && (
                    <div>
                      <button
                        type="button"
                        className="rounded-md  border px-4 py-2 text-base font-medium text-indigo-600 bg-indigo-100 border-indigo-600 shadow-sm sm:text-sm"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                  {buttonName && (
                    <div>
                      <button
                        type="submit"
                        className={
                          type === 'danger'
                            ? 'rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 sm:text-sm'
                            : 'rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:text-sm'
                        }
                        onClick={() => {
                          onPrimaryButtonClick(id);
                          setOpen(false);
                        }}
                      >
                        {buttonName}
                      </button>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
