import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Input } from './twEssential';
import Select from 'react-select';
import { capitalize } from '../utils/captilizeHelper';
import { DateRangePicker } from 'react-date-range';
import { subDays } from 'date-fns';
import { Popover, Box } from '@mui/material';

export const Filter = function (props) {
  let {
    filterDialog,
    setFilterDialog,
    setTicketDetails,
    ticketDetails,
    selectedOption,
    setSelectedOption,
    getSelectedOption,
    selectedDateRange,
    setSelectedDateRange,
    selectedType,
    setSelectedType,
    FilterOptions,
    state,
    defaultFilterOptions,
    setState,
    userDetails,
    setUserDetails,
    projectDetails,
    setProjectDetails,
    selectedAssignee,
    setSelectedAssignee,
    ticketTypes,
    params,
    setDialog,
    showDialog,
  } = props;

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [prevState, setPrevState] = useState([]);

  const ValueOptionsProject = Array.isArray(projectDetails)
    ? projectDetails.map((project) => ({
        id: project?._id,
        label: `${capitalize(project?.name)}`,
        value: project?.name,
      }))
    : [];

  const ValueOptionsProjectType = Array.isArray(projectDetails)
    ? projectDetails.map((project) => ({
        id: project?._id,
        label: `${capitalize(project?.type)}`,
        value: project?.type,
      }))
    : [];

  const ValueOptionsProjectStatus = Array.isArray(projectDetails)
    ? [...new Set(projectDetails.map((project) => project.status))].map((status) => ({
        id: status,
        label: capitalize(status),
        value: status,
      }))
    : [];

  const handleTextFieldClick = (event) => {
    setIsDatePickerOpen(!isDatePickerOpen);
    setAnchorEl(event.currentTarget);
    setPrevState([...state]);
  };

  const getOptionElement = (value) => {
    switch (value) {
      case 'dateRange':
        return (
          <>
            <div className="mb-4">
              <Input
                type="text"
                className="mb-4"
                onClick={handleTextFieldClick}
                value={selectedDateRange}
              />
            </div>
            {isDatePickerOpen && (
              <Popover
                open={isDatePickerOpen}
                anchorEl={anchorEl}
                onClose={() => setIsDatePickerOpen(false)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
              >
                <Box sx={{ p: 2 }} className="flex flex-col">
                  <DateRangePicker
                    onChange={(item) => setState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    direction="horizontal"
                    // wrapperClassName="w-32"
                  />
                  <div className="mt-8 sm:mt-6 flex flex-wrap justify-center gap-5">
                    <button
                      type="submit"
                      className="rounded-md border border-transparent bg-indigo-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:text-base w-32 mt-2"
                      onClick={() => {
                        setIsDatePickerOpen(false);
                      }}
                    >
                      Ok
                    </button>
                    <button
                      type="button"
                      className="rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 sm:text-base w-32 mt-2"
                      onClick={() => {
                        setState(prevState);
                        setIsDatePickerOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </Box>
              </Popover>
            )}
          </>
        );
      case 'name':
        return (
          <Select
            name={'value'}
            value={projectDetails}
            options={ValueOptionsProject}
            placeholder={`Select an option`}
            onChange={(chosenProject) => {
              setProjectDetails(chosenProject);
            }}
            menuPosition="fixed"
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              input: (provided) => ({
                ...provided,
                'input:focus': {
                  outline: 'none',
                  boxShadow: 'none',
                },
              }),
            }}
          />
        );
      case 'type':
        return (
          <Select
            name={'value'}
            value={projectDetails}
            options={ValueOptionsProjectType}
            placeholder={`Select an option`}
            onChange={(chosenProject) => {
              setProjectDetails(chosenProject);
            }}
            menuPosition="fixed"
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              input: (provided) => ({
                ...provided,
                'input:focus': {
                  outline: 'none',
                  boxShadow: 'none',
                },
              }),
            }}
          />
        );
      case 'status':
        return (
          <Select
            name={'value'}
            value={projectDetails}
            options={ValueOptionsProjectStatus}
            placeholder={`Select an option`}
            onChange={(chosenProject) => {
              setProjectDetails(chosenProject);
            }}
            menuPosition="fixed"
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              input: (provided) => ({
                ...provided,
                'input:focus': {
                  outline: 'none',
                  boxShadow: 'none',
                },
              }),
            }}
          />
        );
    }
  };
  return (
    <Transition.Root show={showDialog} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-40"
          leave="ease-in duration-200"
          leaveFrom="opacity-40"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-5 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-50 sm:p-6 mx-20">
                <div className="px-4 items-left">
                  <h1 className="text-xl font-medium mt-4">Add Filter</h1>
                  <p className="text-sm text-gray-500 font-medium py-2">
                    First select the column to filter by and then enter the value to filter the
                    data.
                  </p>
                </div>
                <div className="items-center gap-3">
                  <div className="flex flex-col">
                    <label
                      htmlFor="name"
                      className=" my-2 block pl-4 text-l font-normal text-gray-700"
                    >
                      Filter by
                    </label>
                    <div className="p-3">
                      <Select
                        name={'filter by'}
                        value={selectedOption}
                        options={FilterOptions}
                        placeholder={`Select an option`}
                        onChange={(selectedValue) => {
                          setSelectedOption(selectedValue);
                        }}
                        getOptionValue={(option) => option.id}
                        menuPosition="fixed"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          input: (provided) => ({
                            ...provided,
                            'input:focus': {
                              outline: 'none',
                              boxShadow: 'none',
                            },
                          }),
                        }}
                      />
                    </div>
                  </div>
                  {selectedOption?.type && (
                    <>
                      <div className="flex flex-col">
                        <label
                          htmlFor="name"
                          className=" block pl-4 text-l font-normal text-gray-700"
                        >
                          Value
                        </label>
                        <div className="p-3 w-full">{getOptionElement(selectedOption?.type)}</div>
                      </div>
                    </>
                  )}

                  <div className="mt-8 sm:mt-6 flex flex-wrap justify-center gap-5">
                    <button
                      type="submit"
                      className="rounded-md border border-transparent bg-indigo-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:text-base w-32 mt-2"
                      onClick={() => {
                        getSelectedOption(
                          selectedOption,
                          selectedDateRange,
                          selectedType,
                          selectedAssignee,
                          projectDetails,
                        );
                        setDialog(false);
                        setFilterDialog(false);
                        setIsDatePickerOpen(false);
                      }}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="rounded-md  border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 sm:text-base w-32 mt-2"
                      onClick={() => {
                        setDialog(false);
                        setFilterDialog(false);
                        setIsDatePickerOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
