import { createContext, useContext, useState } from 'react';

export const AppContext = createContext();

export function useAppContext() {
  const {
    theme,
    setTheme,
    userProfile,
    setUserProfile,
    globalLoader,
    setGlobalLoader,
    notify,
    setNotify,
    setNotifications,
    notifications,
    unopenedNotificationCount,
    setUnopenedNotificationCount,
    checkAvailabilityLock,
    setCheckAvailabilityLock,
  } = useContext(AppContext);

  return {
    theme,
    setTheme,
    userProfile,
    setUserProfile,
    globalLoader,
    setGlobalLoader,
    notify,
    setNotify,
    setNotifications,
    notifications,
    unopenedNotificationCount,
    setUnopenedNotificationCount,
    checkAvailabilityLock,
    setCheckAvailabilityLock,
  };
}

export function AppProvider({ children }) {
  const [theme, setTheme] = useState('light');
  const [userProfile, setUserProfile] = useState(null);
  const [globalLoader, setGlobalLoader] = useState(false);
  const [notify, setNotify] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [unopenedNotificationCount, setUnopenedNotificationCount] = useState(0);
  const [checkAvailabilityLock, setCheckAvailabilityLock] = useState(true);

  return (
    <AppContext.Provider
      value={{
        theme,
        setTheme,
        userProfile,
        setUserProfile,
        globalLoader,
        setGlobalLoader,
        notify,
        setNotify,
        setNotifications,
        notifications,
        unopenedNotificationCount,
        setUnopenedNotificationCount,
        checkAvailabilityLock,
        setCheckAvailabilityLock,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
