import { sendRequest } from '../utils/apiCall';
import { REGION_GATEWAY } from '../utils/constants';

export const useNotifications = () => {
    const profileString = localStorage.getItem('profile');
    const profile = JSON.parse(profileString);

  const fetchNotifications = async () => {
    let result;
    try {
      if (profile?._id) {
        let { response } = await sendRequest({
          url: `${REGION_GATEWAY}/coreV2/instances/notification?notifyUserId=${profile._id}`,
          method: 'GET',
        });
        if (response.status === 200) {
          result = response?.data?.data?.instances;
        }
      }
    } catch (error) {
      console.log(error);
    }
    if (result) {
      const fetchResponse = result?.map((instance, index) => {
        return {
          id: instance.data,
          message: instance.message,
          navigatePath: instance.navigatePath,
          subject: instance.subject,
          createdBy: instance.data_createdBy,
          createdAt: instance.createdAt,
          isRead: instance.isRead,
        };
      });
      fetchResponse.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      return fetchResponse;
    }
  };

  return {
    fetchNotifications,
  };
};
