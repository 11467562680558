import { configureStore } from '@reduxjs/toolkit';
import screenReducer from './features/screen/screenSlice';
import notificationReducer from './features/screen/notificationSlice';
import apiReducer from './features/screen/apiSlice';

export const store = configureStore({
  reducer: {
    screen: screenReducer,
    notification: notificationReducer,
    api:apiReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
