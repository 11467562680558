// @ts-nocheck
/* eslint no-underscore-dangle: ["error", { "allow": ["_env_"] }] */

export const {
  REGION,
  REGION_GATEWAY,
  LOGO_URL,
  LOGIN_UI,
  APP_UI_V1,
  REPORTS_URL,
  SERVICES,
  PUSHER_KEY,
  PUSHER_CLUSTER,
  NOTIFICATION_SERVICE,
  WSPORT,
  WSHOST,
  TRACKING_CODE,
  AZURE_AD_CLIENT_ID,
  AZURE_AD_AUTHORITY,
  AZURE_AD_GRAPH_PROFILE,
  GATEWAY,
  TENANT_SUBDOMAIN_OPTIONS,
} = window._env_;

export const BANNER_IMAGE_URL =
  window._env_.BANNER_IMAGE_URL ||
  'https://dev-assets-b378be3420368636.s3.amazonaws.com/assets/public/banner-1.png';

export const BANNER_BG_STYLE = window._env_.BANNER_BG_STYLE || '1';

export const FILE_SIZE_ERROR_MESSAGE = 'Sorry, file cannot exceed 10MB.';

export const ROOT_PATH = '/';
export const SCREEN_PATH = '/:screen';
export const SCREEN_SID_PATH = '/:service/:sId';
export const SCREEN_SID_SCREEN_PATH = '/:service/:sId/:screen';
export const SCREEN_SID_UNIQUE_SCREEN_PATH = '/:service/:sId/:id/:screen';
export const UNAUTHORIZED_PATH = '/unauthorized';
export const ERROR_PATH = '/error';
export const LOGIN_PATH = '/login';
export const AZURE_AUTH_PATH = '/auth/ad';
export const AZURE_AUTH_LINK_PATH = '/auth/ad/link';
