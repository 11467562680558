// @ts-nocheck

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import Analytics from './components/Analytics';
import { TRACKING_CODE, AZURE_AD_CLIENT_ID } from './utils/constants';

// MSAL imports
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './auth/adAuthConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

if (AZURE_AD_CLIENT_ID) {
  msalInstance.initialize().then(() => {
    console.info('MSAL INSTANCE INITIALIZING.....');
    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
      }
    });

    const root = ReactDOM.createRoot(document.getElementById('root'));

    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <App msalInstance={msalInstance} />
        </Provider>
        {TRACKING_CODE && <Analytics trackingID={TRACKING_CODE} />}
      </React.StrictMode>,
    );
  });
} else {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App msalInstance={msalInstance} />
      </Provider>
      {TRACKING_CODE && <Analytics trackingID={TRACKING_CODE} />}
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// @ts-ignore
reportWebVitals();
