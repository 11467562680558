import React from 'react';
import { Tooltip } from 'react-tooltip';
import { capitalize } from '../utils/captilizeHelper';
import { P } from './twEssential';

const TooltipComponent = ({ content, type, id, place = 'left', className = '' }) => {
  const tooltipContent =
    type === 'multiple'
      ? Object.entries(content).map(([key, value]) => (
          <React.Fragment key={key}>
            <div className="text-left">
              {capitalize(key)}: {value}
            </div>
          </React.Fragment>
        ))
      : null;

  return (
    <div>
      {type === 'single' && (
        <div className="absolute z-50 top-1/2 left-full transform -translate-y-1/2 translate-x-1 py-1 px-3 bg-[#6C727F] text-white text-sm rounded whitespace-nowrap opacity-0 pointer-events-none group-hover:opacity-100 group-hover:translate-x-1 transition-opacity duration-300">
          <p>{content}</p>
        </div>
      )}

      {type === 'multiple' && (
        <div>
          <Tooltip anchorSelect={`#field-${id}`} place="right" className="z-50">
            {tooltipContent}
          </Tooltip>
        </div>
      )}

      {type === 'multipleWithClass' && (
        <div className="fixed z-[99999] top-[400px] left-[500px] transform -translate-y-1/2 translate-x-1 py-1 px-3 bg-[#6C727F] text-white text-sm rounded whitespace-nowrap opacity-0 pointer-events-none group-hover:opacity-100 group-hover:translate-x-1 transition-opacity duration-300">
          {Object.entries(content).map(([key, value]) => (
            <React.Fragment key={key}>
              <div className="text-left">
                {capitalize(key)}: {value}
              </div>
            </React.Fragment>
          ))}
        </div>
      )}

      {type === 'tooltipSingle' && (
        <div>
          <Tooltip anchorSelect={`#field-${id}`} place={place} className={className}>
            {content}
          </Tooltip>
        </div>
      )}
    </div>
  );
};
export default TooltipComponent;
