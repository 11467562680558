import { sendRequest } from '../utils/apiCall';
import { REGION_GATEWAY } from '../utils/constants';

export const useProfileData = () => {
  const profile = localStorage.getItem('profile');

  const getUserProfile = async () => {
    // return profile if found in localstorage
    if (profile) {
      return JSON.parse(profile);
    }
    try {
      const { response, responseError } = await sendRequest({
        url: `${REGION_GATEWAY}/coreV2/getProfile`,
        method: 'GET',
      });
      if (response && response.status === 200) {
        const fieldData = response.data.data;
        localStorage.setItem('profile', JSON.stringify(fieldData));
        return fieldData;
      }
      if (responseError && responseError.response.status === 401) {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  return {
    getUserProfile,
  };
};
