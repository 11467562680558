import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../provider/AppProvider';
import { Icons } from '@regere-ai/blocks-v2';
import { REGION_GATEWAY } from '../../utils/constants';
import { sendRequest } from '../../utils/apiCall';
import { getProfileFromLocalStorage } from '../../utils/helperFunctions';
import { useProfileData } from '../../hooks/useProfileData';
import { useNotifications } from '../../hooks/useNotifications';
import { HandRaisedIcon, InboxArrowDownIcon, ShieldExclamationIcon, ShoppingCartIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/outline';

const NotificationAlert = (props:any) => {
  const { notificationData } = props;
  const [visible, setVisible] = useState(false);
  const { setNotify } = useAppContext();
  const { getUserProfile } = useProfileData();
  const { fetchNotifications } = useNotifications();
  const currentProfile = getProfileFromLocalStorage();

  useEffect(() => {
    (async () => {
      await getUserProfile();
      await fetchNotifications();
    })();
  }, []);

  useEffect(() => {
   if (notificationData) {
     setVisible(true);
     const timer = setTimeout(() => {
       setVisible(false);
       setNotify(null);
     }, 10000);
     return () => clearTimeout(timer);
   }
  }, [notificationData]);

  if (!notificationData || !visible) return null;

  const moduleIcon = (path) => {
    if (path.startsWith('/coreV2/ticket-quality_ticket')) {
      return (
        <div className="mx-auto flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ShieldExclamationIcon className="h-5 w-5 text-black-500" aria-hidden="true" />
        </div>
      );
    }else if (path.startsWith('/coreV2/ticket-machine_breakdown_ticket')) {
      return (
        <div className="mx-auto flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <WrenchScrewdriverIcon className="h-5 w-5 text-black-500" aria-hidden="true" />
        </div>
      );
    } else if (path.startsWith('/coreV2/ticket-call_supervisor_ticket')) {
      return (
        <div className="mx-auto flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <HandRaisedIcon className="h-5 w-5 text-black-500" aria-hidden="true" />
        </div>
      );
    }else if (path.startsWith('/coreV2/ticket-pending_item_material')) {
      return (
        <div className="mx-auto flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ShoppingCartIcon className="h-5 w-5 text-black-500" aria-hidden="true" />
        </div>
      );
    }else if (path.startsWith('/coreV2/ticket-material_shortage')) {
      return (
        <div className="mx-auto flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ShoppingCartIcon className="h-5 w-5 text-black-500" aria-hidden="true" />
        </div>
      );
    }else if (path.startsWith('/coreV2/ticket-pending_item_process')) {
      return (
        <div className="mx-auto flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <InboxArrowDownIcon className="h-5 w-5 text-black-500" aria-hidden="true" />
        </div>
      );
    } else if (path.startsWith('/checklist-service/checklist_instances-all/')) {
      return (
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
          <Icons data={'DocumentMagnifyingGlassIcon'} />
        </div>
      );
    } else if (path.startsWith('/coreV2/ticket-checklist_ticket')) {
      return (
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
          <Icons data={'DocumentMagnifyingGlassIcon'} />
        </div>
      );
    }
  };

  const updateNotify =async(data)=>{
    if (data) {
      try {
        const { response, responseError } = await sendRequest({
          url: `${REGION_GATEWAY}/coreV2/instances/notification?data=${data}&notifyUserId=${currentProfile._id}`,
          method: 'GET',
        });
        if (response && response.status === 200) {
          let _id = response.data.data.instances[0]._id;
          try {
            let { response } = await sendRequest({
              url: `${REGION_GATEWAY}/coreV2/instances/notification/${_id}`,
              method: 'PUT',
              body: {
                data: {
                  isRead: true,
                },
              },
            });
            if (response.status === 200) {
              await fetchNotifications();
            }
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  const handleNotificationClick = async (navigatePath,data) => {
     await updateNotify(data);
     window.open(navigatePath, '_blank');
  };

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-[100]"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end mt-7 mr-10">
          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition transform ease-out duration-300 translate-y-0 opacity-100">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">{moduleIcon(notificationData?.navigatePath)}</div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">{notificationData.subject}</p>
                  <p className="mt-1 text-sm text-gray-500">{notificationData.message}</p>
                  <div className="mt-3 flex space-x-7">
                    <button
                      type="button"
                      className="rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        handleNotificationClick(
                          notificationData?.navigatePath,
                          notificationData?.data,
                        );
                      }}
                    >
                      Open
                    </button>
                    <button
                      type="button"
                      className="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setVisible(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setVisible(false)}
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationAlert;
