import React, { useEffect, useState } from 'react';
import { sendRequest } from '../utils/apiCall';
import { REGION_GATEWAY } from '../utils/constants';
import Popup from './Popup';
import { useSelector, useDispatch } from 'react-redux';
import { setFragmentData } from '../features/screen/screenSlice';
import { getFragmentKey } from '../utils/helperFunctions';

function LayoutFragment(props) {
  const {
    params,
    resetState,
    contextDetails,
    contextVars,
    contextVarsPropertyMap,
    fetchCounts,
    filterParams,
  } = props;
  let [layoutData, setLayoutData] = useState([]);
  let [entityData, setEntityData] = useState([]);
  let [entityExtensionData, setEntityExtensionData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const fragmentKey = getFragmentKey(params);
  let fragmentData = useSelector((state) => state.screen.data[fragmentKey]);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      let { response } = await sendRequest({
        url: `${REGION_GATEWAY}/${params.service}/instances/layout?sId=${params.sId}&screen=${
          params.screen ? params.screen : 'list'
        }`,
        method: 'GET',
      });
      setLayoutData(response.data?.data?.instances[0]);

      let entityResponse = await sendRequest({
        url: `${REGION_GATEWAY}/${params.service}/entity?sId=${params.sId}`,
        method: 'GET',
      });
      let entityMaster = entityResponse.response.data?.data;
      setEntityData(entityMaster?.Entity[0]);
      if (entityMaster.extensionEntity?.length) {
        setEntityExtensionData(entityMaster.extensionEntity[0]);
      }
      setShowPopup(true);
      if (contextDetails) {
        const updatedContextVars = contextVars.reduce((result, varName) => {
          const propertyKey = getPropertyKey(varName);
          result[varName] = contextDetails[propertyKey];
          return result;
        }, {});
        dispatch({
          ...setFragmentData({ ...fragmentData, contextVars: updatedContextVars }),
          fragmentKey: fragmentKey,
        });
      }
    }

    fetchData();
  }, []);

  function handlePopClose() {
    setShowPopup(false);
    resetState();
  }

  function getPropertyKey(varName) {
    const propertyMap = contextVarsPropertyMap;
    return propertyMap[varName];
  }

  return (
    <>
      {showPopup ? (
        <Popup
          showPopup={showPopup}
          params={params}
          handleClose={() => {
            handlePopClose();
            fetchCounts();
          }}
          key={params.sId}
          layout={layoutData}
          entity={entityData}
          extensionEntity={entityExtensionData}
          filterParams={filterParams}
        ></Popup>
      ) : null}
    </>
  );
}

export default LayoutFragment;
