import { useEffect, useState } from 'react';
import { Engine } from 'json-rules-engine';
import _ from 'lodash'; // to use the lodash path resolver, for example
import { getProfileFromLocalStorage } from '../utils/helperFunctions';

export const ShouldRenderComponent = (props) => {
  const { visibilityCondition, children, currentFragment } = props;
  const [render, setRender] = useState(true);
  useEffect(() => {
    const figureOut = async () => {
      setRender(await shouldItRender(visibilityCondition, currentFragment));
    };

    figureOut();
  }, [setRender]);
  if (render) {
    return children;
  }
  return null;
};

function pathResolver(object, path) {
  return _.get(object, path);
}

const shouldItRender = async (visibilityCondition, currentFragment) => {
  try {
    const profileHash = getProfileFromLocalStorage();
    let result = true;
    const conditionsWithEvent = {
      conditions: visibilityCondition,
      event: {
        type: 'no-event',
      },
    };
    let engine = new Engine([], { pathResolver: pathResolver, allowUndefinedFacts: true });
    if (visibilityCondition) {
      engine.addRule(conditionsWithEvent || undefined);
      let facts = {
        uiVisibilityHash: profileHash.uiVisibilityHash,
        roles: profileHash.roles.map((_role) => _role.label),
        currentFragment,
      };
      const output = await engine.run(facts);
      // Assuming there can be only one rule
      if (output.results.length) {
        result = true;
      } else {
        result = false;
      }
    }
    return result;
  } catch (e) {
    return true;
  }
};

const getObjectValue = (obj, str) => {
  const keys = str.split('.');
  let value = obj;

  for (let key of keys) {
    if (value && value.hasOwnProperty(key)) {
      value = value[key];
    } else {
      value = undefined;
      break;
    }
  }

  return value;
};
