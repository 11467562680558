import axios from 'axios';
import { REGION_GATEWAY } from './constants';
import { getCookie } from './storageHelpers';

export const sendRequest = async (requestConfig, processData = (e) => {}) => {
  let { url, method = 'GET', body = null, headers = {}, isPublic = false } = requestConfig;
  let response = null;
  let responseError = null;
  try {
    if (method === 'GET') {
      response = await axios.get(url, {
        headers: !isPublic
          ? { Authorization: `Bearer ${getCookie('access_token')}`, ...headers }
          : null,
      });
    } else if (method === 'POST') {
      response = await axios.post(url, body, {
        headers: { Authorization: `Bearer ${getCookie('access_token')}`, ...headers },
      });
    } else if (method === 'PATCH') {
      response = await axios.patch(url, body, {
        headers: { Authorization: `Bearer ${getCookie('access_token')}`, ...headers },
      });
    } else if (method === 'PUT') {
      response = await axios.put(url, body, {
        headers: { Authorization: `Bearer ${getCookie('access_token')}`, ...headers },
      });
    }
    processData(response);
  } catch (err) {
    responseError = err;
  }
  return { response, responseError };
};
