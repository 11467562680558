import React, { Suspense } from 'react';
import { COMPONENT_MAP } from './componentMap';

export function getLayoutColClass(grid) {
  let column;

  switch (grid?.count) {
    case '2':
      column = 'grid-cols-2';
      break;
    case '3':
      column = 'grid-cols-3';
      break;
    case '4':
      column = 'grid-cols-4';
      break;
    case '5':
      column = 'grid-cols-5';
      break;
    case '6':
      column = 'grid-cols-6';
      break;
    case '7':
      column = 'grid-cols-7';
      break;
    case '8':
      column = 'grid-cols-8';
      break;
    case '9':
      column = 'grid-cols-9';
      break;
    case '10':
      column = 'grid-cols-10';
      break;
    case '11':
      column = 'grid-cols-11';
      break;
    case '12':
      column = 'grid-cols-12';
      break;
    default:
      column = 'grid-cols-1';
  }

  return column;
}

export function getSpanClass(span) {
  let column;

  switch (span) {
    case '2':
      column = 'col-span-2';
      break;
    case '3':
      column = 'col-span-3';
      break;
    case '4':
      column = 'col-span-4';
      break;
    case '5':
      column = 'col-span-5';
      break;
    case '6':
      column = 'col-span-6';
      break;
    case '7':
      column = 'col-span-7';
      break;
    case '8':
      column = 'col-span-8';
      break;
    case '9':
      column = 'col-span-9';
      break;
    case '10':
      column = 'col-span-10';
      break;
    case '11':
      column = 'col-span-11';
      break;
    case '12':
      column = 'col-span-12';
      break;
    default:
      column = 'col-span-1';
  }

  return column;
}
export function gatGapClass(data) {
  let gap;
  switch (data) {
    case 'no-gap':
      gap = null;
      break;
    default:
      gap = ' gap-1';
      break;
  }
  return gap;
}
export function getWidth(grid) {
  return grid;
}
export function getBgColor(bgColor) {
  return bgColor;
}

export function getHeight(height: string) {
  let heightClass = '';
  switch (height) {
    case 'h-full':
      heightClass = 'h-full';
      break;
  }
  return heightClass;
}

export const renderComponents = (
  components,
  actions,
  entity,
  extensionEntity,
  params,
  query,
  fields,
  closePopup,
  filterParams,
) =>
  components.map((_c, index) => {
    const COMPONENT = COMPONENT_MAP[_c.type];
    return (
      <Suspense key={`component_suspense_${index}`} fallback={<div>Loading...</div>}>
        <COMPONENT
          key={`component_map_${index}`}
          data={_c}
          actions={actions}
          entity={entity}
          extensionEntity={extensionEntity}
          params={params}
          query={query}
          fields={fields}
          closePopup={closePopup}
          filterParams={filterParams}
        />
      </Suspense>
    );
  });
