import React from 'react';
import loadingImage1 from '../animations/67048-wrong.json';
import Lottie from 'lottie-react';

export default function ErrorPage() {
  return (
    <>
      <main>
        <div className="flex flex-col text-center h-screen justify-center items-center">
          <div className="flex justify-center">
            <Lottie animationData={loadingImage1} loop={true} style={{ height: '300px' }} />
          </div>
          <div className="flex flex-col">
            <p className="text-base font-semibold text-indigo-600">Error Code: REGERE_777</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Oops! Something went wrong
            </h1>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Sorry, we have been notified and we are working hard to fix it.{' '}
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Go back home
              </a>
              <a href="#" className="text-sm font-semibold text-gray-900">
                Contact support <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
