/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { XMarkIcon, XCircleIcon } from '@heroicons/react/20/solid';

export default function Notification(props) {
  const [show, setShow] = useState(false);
  const { message, type, status } = props;
  const isSuccess = type === 'success';
  const icon = isSuccess ? (
    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
  ) : (
    <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
  );

  useEffect(() => {
    if (status) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [status]);

  return (
    <>
      <div className="relative bg-no-repeat">
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`rounded-md p-4 z-50 absolute w-full top-2 left-0 ${
              isSuccess ? 'bg-green-100' : 'bg-red-100'
            }`}
          >
            <div className="flex">
              <div className="flex-shrink-0">{icon}</div>
              <div className="ml-3">
                <h3
                  className={`text-sm font-medium ${isSuccess ? 'text-green-800' : 'text-red-800'}`}
                >
                  {message}
                </h3>
              </div>
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    className={`rounded-md inline-flex focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                      isSuccess
                        ? 'text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50'
                        : 'text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50'
                    }`}
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    <span className="sr-only">Dismiss</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
}
