import { loginRequest, apiConfig } from './adAuthConfig';
import { msalInstance } from '../index';
import { AZURE_AD_GRAPH_PROFILE } from '../utils/constants';

export async function callApi(accessToken: any) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.',
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(AZURE_AD_GRAPH_PROFILE, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
