import { useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { loginRequest } from './adAuthConfig';
import { ChevronRightIcon } from '@heroicons/react/24/solid';

export const AzureSignIn = ({ profile }) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleLogin = async (loginType) => {
    setAnchorEl(null);

    if (loginType === 'popup') {
      /**
       * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
       * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
       * For more information, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
       */
      instance.loginPopup({
        ...loginRequest,
        redirectUri: '/auth/ad', // e.g. /redirect
      });
    } else if (loginType === 'redirect') {
      await instance.loginRedirect(loginRequest);
    }
  };

  return (
    <>
      <div className="flex">
        <button
          className="px-4 py-5 sm:p-6 text-left w-full"
          onClick={() => {
            isAuthenticated ? alert('account already linked') : handleLogin('redirect');
          }}
        >
          <p className="px-10 text-m flex leading-5">
            <span className="pr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0 0 48 48"
              >
                <path fill="#ff5722" d="M6 6H22V22H6z" transform="rotate(-180 14 14)"></path>
                <path fill="#4caf50" d="M26 6H42V22H26z" transform="rotate(-180 34 14)"></path>
                <path fill="#ffc107" d="M26 26H42V42H26z" transform="rotate(-180 34 34)"></path>
                <path fill="#03a9f4" d="M6 26H22V42H6z" transform="rotate(-180 14 34)"></path>
              </svg>{' '}
            </span>

            {isAuthenticated ? (
              <span>User ID: {profile?.id}. Awesome...! Azure AD linked. </span>
            ) : (
              <span className="bold">Link with Microsoft Azure Active Directory</span>
            )}
          </p>
        </button>
      </div>
    </>
  );
};
