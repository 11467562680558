import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  tour: false,
  tourConfig: '',
  data: {},
  comment: '',
  otherInstances: {},
  bulkUploadInstances: [],
  disableButton: false,
  restrictNavigation: false,
  selectedQuery: '',
};

export const screenSlice = createSlice({
  name: 'screen',
  initialState,
  reducers: {
    setFragmentData: (state, action) => {
      const fragmentKey = action.fragmentKey;
      const payload = action.payload;
      state.data = {
        ...state.data,
        [fragmentKey]: {
          ...payload,
        },
      };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTour: (state, action) => {
      state.tour = action.payload;
    },
    setTourConfig: (state, action) => {
      try {
        state.tourConfig = JSON.stringify(action.payload);
      } catch (e) {
        state.tourConfig = undefined;
      }
    },
    setComment: (state, action) => {
      state.comment = action.payload;
    },
    setBulkUploadInstances: (state, action) => {
      state.bulkUploadInstances = action.payload;
    },
    setDisableButtons: (state, action) => {
      state.disableButton = action.payload;
    },
    setRestrictNavigation: (state, action) => {
      state.restrictNavigation = action.payload;
    },
    setSelectedQuery: (state, action) => {
      state.selectedQuery = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTour,
  setTourConfig,
  setFragmentData,
  setLoading,
  setComment,
  setBulkUploadInstances,
  setDisableButtons,
  setRestrictNavigation,
  setSelectedQuery,
} = screenSlice.actions;

export default screenSlice.reducer;
