import { useRouteError } from 'react-router-dom';
import loadingImage1 from '../animations/security.json';
import Lottie from 'lottie-react';

export default function UnauthorizedPage() {
  return (
    <>
      <main>
        <div className="flex flex-col text-center h-screen justify-center items-center">
          <div className="flex" style={{ width: '300px' }}>
            <Lottie animationData={loadingImage1} loop={true} />
          </div>
          <div className="flex flex-col">
            <p className="text-base font-semibold text-indigo-600">
              Error Code: REGERE_UNAUTHORIZED
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Sorry, You are not authorized to access this content.{' '}
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Go back home
              </a>
              <a href="#" className="text-sm font-semibold text-gray-900">
                Contact support <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
