import { sendRequest } from '../utils/apiCall';
import { REGION_GATEWAY } from '../utils/constants';

export const useDashboard = () => {
  let layout = {};

  const getDashboard = async () => {
    try {
      const { response } = await sendRequest({
        url: `${REGION_GATEWAY}/coreV2/getDashboardLayout?sId=user&screen=dashboard`,
        method: 'GET',
      });
      layout = response?.data;
      console.log(layout);
    } catch (error) {
      console.log(error);
    }

    return layout?.instances?.[0];
  };
  return {
    getDashboard,
  };
};
