import { sendRequest } from '../utils/apiCall';
import { REGION_GATEWAY } from '../utils/constants';

export const useSideNav = () => {
  const sideNav = localStorage.getItem('sideNav');
  const getSideNav = async () => {
    // return profile if found in localstorage
    if (sideNav) {
      return JSON.parse(sideNav);
    }
    try {
      let { response, responseError } = await sendRequest({
        url: `${REGION_GATEWAY}/coreV2/getSideMenu`,
        method: 'GET',
      });

      if (response && response.status === 200) {
        const fieldData = response.data.data;
        localStorage.setItem('sideNav', JSON.stringify(fieldData.items));
        return fieldData.items;
      }
      if (responseError && responseError.response.status === 401) {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  return {
    getSideNav,
  };
};
