import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const GoogleAnalytics = ({ trackingID }) => {
  console.log('trackingID');
  console.log(trackingID);
  useEffect(() => {
    // Load gtag.js
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingID}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      // Initialize gtag
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag('js', new Date());
      gtag('config', trackingID);
    };
  }, [trackingID]);

  return (
    <Helmet>
      {/* Add a noscript tag as recommended by Google */}
      <noscript>{`
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=${trackingID}"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        />
      `}</noscript>
    </Helmet>
  );
};

export default GoogleAnalytics;
