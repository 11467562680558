import { useEffect, useState } from 'react';

export default function AzureAuthInitialize() {
  const [holdPage, setHoldPage] = useState(true);
  useEffect(() => {
    console.log('========== Azure AD INITIALIZATION =============');
    setTimeout(() => {
      setHoldPage(false);
    }, 2000);
  });

  holdPage && (
    <>
      <div>
        <h1>Azure Auth Initializer</h1>
      </div>
    </>
  );
}
