import React, { useState, useEffect } from 'react';
import { H3 } from './twEssential';

const Spinner = ({ name }) => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    setShowSpinner(true);
    const timeoutId = setTimeout(() => {
      setShowSpinner(false);
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div>
      {showSpinner && (
        <div className="spinner">
          <div className="spinner-dot"></div>
          <div className="spinner-dot"></div>
          <div className="spinner-dot"></div>
          <div>
            {' '}
            <H3 className="text-gray-700">{name}</H3>{' '}
          </div>
        </div>
      )}
    </div>
  );
};

export default Spinner;
